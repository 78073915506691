<template>
  <Stack direction="col" gap="none">
    <div v-for="(cartItem, index) in cart?.line_items.slice().reverse()" :key="cartItem.sku" class="relative w-full">
      <!-- @ts-ignore -->
      <BasketCard
        @delete="handleRemoveItem(cartItem.sku)"
        @update-total="handleUpdateTotal(cartItem.sku, $event)"
        @link="handleLink(cartItem.name, cartItem.sku)"
        data-test-id="cartTrayItems"
        data-fs="cartTrayItems"
        variant="small"
        :class="index === 0 && basketTrayFirstOpen ? '!bg-grey-light' : 'bg-white'"
        :content="{
          image: cartItem.image_url.includes('.gif') ? IMAGES.PLACEHOLDER : cartItem.image_url,
          title: cartItem.name,
          subtitle: 'Product code: ' + cartItem.sku,
          quantity: cartItem.is_tally ? cartItem.tally.length : cartItem.quantity,
          vat: isVatIncluded ? true : false,
          unit: productUnitTranslation(`${cartItem.unit}` as ProductDetailsType) as string,
          priceEach: isVatIncluded ? cartItem.price_inc_vat : cartItem.price,
          total: isVatIncluded ? cartItem.subtotal_inc_vat : cartItem.subtotal,
          collection: cartItem.availability_flag === 4 ? false : true,
          hasVat: isVatIncluded,
          tallyUnit: cartItem.is_tally ? cartItem.tally.unit : null,
          alert: getAlertMessage(cartItem.availability_flag).value,
          alertVariant: cartItem.availability_flag === 3 ? 'error' : 'information',
        }"
      />
      <div class="w-full bg-mkm-blue-default h-[5px] relative shim" v-show="isLoading[cartItem.sku]" />
    </div>
  </Stack>
  <Stack
    class="p-[24px] w-full sticky bottom-none bg-white"
    align="center"
    justify="center"
    direction="col"
    style="box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25)"
  >
    <Stack gap="none" class="w-full" justify="between">
      <Text class="w-auto">Basket Sub-total:&nbsp;</Text>
      <Text class="w-auto text-right" weight="bold" data-test-id="cartTraySubTotal" data-fs="cartTraySubTotal"
        >£{{ isVatIncluded ? cart?.totals?.total.toFixed(2) : cart?.totals?.subtotal.toFixed(2) }}</Text
      >
    </Stack>
    <Stack class="w-full" align="center" justify="center" gap="2xs">
      <Button
        variant="neutral"
        size="md"
        class="!w-1/2 !justify-center"
        @click="navigateToCart"
        data-test-id="cartTrayReviewBtn"
        data-fs="miniBasketReviewButton"
        >BASKET</Button
      >
      <Button
        :disabled="isButtonDisabled || isCheckingOut"
        variant="primary"
        size="md"
        class="!w-1/2 !justify-center"
        data-test-id="cartTrayCheckoutBtn"
        data-fs="miniBasketCheckoutButton"
        @click="handleCheckout"
        >CHECKOUT<Spinner v-show="isCheckingOut"
      /></Button>
    </Stack>
  </Stack>
</template>
<script setup lang="ts">
import debounce from "lodash.debounce";
import { ROUTES } from "@/helpers/routes/routes";
import { IMAGES } from "@/constants/images";
import { useGA4EcommerceEvents } from "mkm-gtm";
import { productUnitTranslation, type ProductDetailsType } from "~/utils/productUnitTranslation";

const { setBasketTray, isVatIncluded, deliveryMethod, basketTrayFirstOpen, basketTray } = useUIState();
const { selectedBranch } = useBranches();
const { user } = useUser();
const { login } = useCheckout();
const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();

const customerCategory = user.value?.account.business_type.split("|");

const { cart, removeCartItem, changeItemQuantity } = useCart();
const router = useRouter();

const isCheckingOut = ref<boolean>(false);
const cartObject = ref(cart);
const productSku = ref<string>("");
const productQuantity = ref<number>(1);
const isLoading = ref<Record<string, boolean>>({});

const isButtonDisabled = computed(() => {
  return cartObject.value?.line_items?.some((item) => item.availability_flag === 3);
});

const closeTray = () => {
  setBasketTray();
};

const handleRemoveItem = async (sku: string) => {
  isLoading.value[sku] = true;
  await removeCartItem(sku);
  isLoading.value[sku] = false;
};

const updateBasket = debounce(async (sku: string) => {
  isLoading.value[sku] = true;
  if (productQuantity.value <= 0) {
    return;
  }
  await changeItemQuantity(productSku.value, productQuantity.value).then(() => {
    isLoading.value[sku] = false;
  });
}, 100);

const handleUpdateTotal = async (sku: string, quantity: number) => {
  productSku.value = sku;
  productQuantity.value = quantity;
  updateBasket(sku);
};

const navigateToCart = async () => {
  await navigateTo({ path: ROUTES.CART });
  closeTray();
};

const handleLink = (name: string, sku: string) => {
  const newName = name.replace(/\s/g, "-").toLocaleLowerCase();
  const newSku = sku.toLowerCase();
  setBasketTray();
  router.push({
    path: `/product/${newName}-${newSku}`,
  });
};

const handleCheckout = async () => {
  isCheckingOut.value = true;
  if (user.value) {
    const redirectTo = await login({
      params: {
        delivery: deliveryMethod.value,
      },
    });
    if (redirectTo) {
      return (window.location.href = redirectTo);
    }
  } else {
    setBasketTray();
    router.push({ path: ROUTES.CHECKOUT_LOGIN });
  }
  isCheckingOut.value = false;
};

const getAlertMessage = (flag: number) =>
  computed(() => {
    const branchName = selectedBranch?.value?.name || "the selected branch";
    switch (flag) {
      case 3:
        return `This item is not available at ${branchName}. Please remove it to proceed with your order.`;
      case 4:
        return "This item will be delivered by one of our trusted suppliers within 7 days.";
      default:
        return null;
    }
  });

const extractItemsFromCart = () => {
  return (
    cartObject.value?.line_items?.map((item: any) => ({
      item_id: item?.sku ?? null,
      item_name: item?.name ?? null,
      price: item?.price_inc_vat ?? null,
      price_ex_vat: item.price ?? null,
      bigcommerce_item_id: item?.product_id.toString() ?? null,
      quantity: item.quantity ?? null,
      stock_type: item?.is_dropship ? "DROPSHIP" : "BRANCH STOCKED",
    })) || []
  );
};

watch(basketTray, async (newValue) => {
  // only log that event when the basket tray is opened
  if (!newValue) return;
  const items = extractItemsFromCart();
  const userCategory = user.value?.account.business_type.split("|");
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  await pushGA4EcommerceEvent(GA4EcommerceEvents.VIEW_CART_MODAL, {
    logged_in: user.value !== null || false,
    user_id: user.value?.id ?? null,
    customer_category_1: user ? isTrade : "Guest",
    customer_category_2: customerCategory?.[1] ?? null,
    customer_category_3: customerCategory?.[2] ?? null,
    account_type: user.value?.account.type ?? null,
    selected_branch: selectedBranch.value?.name ?? null,
    page_type: newValue ? "cart_modal" : "cart_page",
    total_order_value_ex_vat: cart.value?.totals.subtotal ?? null,
    ecommerce: {
      currency: "GBP",
      value: cart.value?.totals?.total ?? null,
      items,
    },
  });
});
</script>
