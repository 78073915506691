<template>
  <LazyDrawer position="right" :is-open="basketTray" :hide-scroll="false">
    <template #header>
      <Stack tag="div" align="start" justify="end">
        <button
          @click="closeTray()"
          class="text-charcoal-default justify-self-end"
          data-test-id="closeCartTrayBtn"
          data-fs="closeCartTrayBtn"
        >
          <Icon name="plus-thick" filled class="text-charcoal-default rotate-45" :size="16" />
          <span class="sr-only">Close Cart Tray</span>
        </button>
      </Stack>

      <Stack
        align="end"
        gap="xs"
        justify="start"
        class="w-fit"
        data-test-id="cartTrayItemNumber"
        data-fs="cartTrayItemNumber"
      >
        <Heading tag="h3" size="xs" class="mt-xs whitespace-nowrap">Your basket</Heading>
        <Text
          v-if="cart?.line_items?.length"
          class="mt-xs"
          data-fs="cartTrayItemNumber"
          data-test-id="cartTrayItemNumber"
          >{{ cart?.line_items?.length }} items</Text
        >
      </Stack>
      <Banner variant="error" v-if="cart && isError" class="mt-sm"
        >Unfortunately one or more items in your basket are not available for purchase from your selected branch. Please
        remove these items from your basket in order to proceed with your order</Banner
      >
    </template>

    <aside class="h-full max-h-screen flex flex-col justify-between" data-test-id="cartTrayOpen" data-fs="cartTrayOpen">
      <Basket v-if="cart" />
      <EmptyBasket v-else />
    </aside>
  </LazyDrawer>
</template>

<script setup lang="ts">
const { setBasketTray, basketTray } = useUIState();
const { cart } = useCart();

const cartObject = ref(cart);

const isError = computed(() => {
  return cartObject.value?.line_items?.some((item) => item.availability_flag === 3);
});
const closeTray = () => {
  setBasketTray();
};
</script>
